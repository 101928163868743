.MuiDropzoneArea-root{
    min-height: auto!important;
    padding: 1em;
}

@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}

.loading-dialog .MuiDropzoneArea-root{
    background-color: #5e5e5e!important;
}

.loading-dialog .MuiDropzoneArea-root::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    content: "";
    height: 40px;
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}

.MuiDropzonePreviewList-root{
    padding-top: 1.5em;
    justify-content: center;
}